import Axios from "axios";
import React, { Component } from "react";
import { Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import FormMessage from "../../components/Shared/FormMessage";
import ProsusCard from "../../components/Shared/ProsusCard";
import Auth from "../../services/Auth";

import Parser from "../../services/Parser";
import Settings from "../../services/Settings";

import Utils from "../../services/Utils";

class Saml extends Component {
  axiosCancelRef = null;

  constructor(props) {
    super(props);

    this.state = {
      e: Utils.getQueryParam("e"),
      formError: null,
      isValidating: false,
      redirectUrl: false,
    };

    this.handleSamlLogin = this.handleSamlLogin.bind(this);
  }

  componentDidMount() {
    this.axiosCancelRef = Axios.CancelToken.source();
    this.handleSamlLogin();
  }

  componentWillUnmount() {
    this.axiosCancelRef.cancel();
  }

  handleSamlLogin() {
    const { e } = this.state;
    const selfRef = this;

    if (!e) return;

    this.setState({ isValidating: true, formError: null });

    Axios.get(
      Settings.apiUrl() + "user-auth/" + e,
      Settings.defaultSettings(this.axiosCancelRef.token)
    )
      .then((response) => {
        Utils.ensureJson(response);

        if (
          !response.data.hasOwnProperty("access_token") ||
          !response.data.hasOwnProperty("expires_at")
        ) {
          this.setState({
            isValidating: false,
            formError: "No authentication token returned",
          });
          return;
        }
        const jwtToken = response.data.access_token;
        const expiresAt = response.data.expires_at;

        let decoded = Utils.decodeBase64(jwtToken.split(".")[1]);
        decoded = JSON.parse(decoded);
        const jwtId = decoded.uid;
        const role = decoded.role;

        this.setState({ isValidating: false, formError: "" });
        Auth.login(jwtToken, expiresAt, jwtId, response.data.name, role);

        const redirect =
          Utils.getQueryParam("redirect") === ""
            ? "/"
            : Utils.getQueryParam("redirect");

        this.props.loggedIn(jwtId, role, redirect);
      })
      .catch(function (error) {
        const msg = Parser.parseErrorResponse(error, false);

        selfRef.setState({
          isValidating: false,
          formError: msg,
          redirectUrl: null,
        });
      });
  }

  renderRedirect() {
    const { redirectUrl } = this.state;

    if (!redirectUrl) return <></>;

    window.location.replace(redirectUrl);
  }

  render() {
    const { formError } = this.state;

    if (!formError) {
      return <p>Waiting to be signed in...</p>;
    }

    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <Card.Body>
                    <h3>Login Failed</h3>

                    <Row>
                      <FormMessage
                        valid={!formError}
                        message={"Reason: " + formError}
                      />{" "}
                    </Row>
                  </Card.Body>
                </Card>
                <ProsusCard />
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Saml;
