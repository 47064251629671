// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faAddressBook,
  faAddressCard,
  faArchive,
  faArrowDown,
  faArrowUp,
  faAt,
  faBuilding,
  faCheck,
  faColumns,
  faCommentAlt,
  faDollarSign,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileContract,
  faFileDownload,
  faFileUpload,
  faFilter,
  faGenderless,
  faHandshake,
  faImage,
  faInfoCircle,
  faKey,
  faLock,
  faMapMarker,
  faMinus,
  faPencilAlt,
  faPeopleArrows,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSave,
  faSync,
  faThList,
  faTimes,
  faToolbox,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUser,
  faUsers,
  faUserShield,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressBook,
  faAddressCard,
  faArchive,
  faArrowDown,
  faArrowUp,
  faAt,
  faBuilding,
  faCheck,
  faColumns,
  faCommentAlt,
  faDollarSign,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileContract,
  faFileDownload,
  faFileUpload,
  faFilter,
  faGenderless,
  faHandshake,
  faImage,
  faInfoCircle,
  faKey,
  faLock,
  faMapMarker,
  faMinus,
  faPencilAlt,
  faPeopleArrows,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSave,
  faSync,
  faThList,
  faTimes,
  faToolbox,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUser,
  faUsers,
  faUserShield,
  faUserTie
);
