import { createStore, compose, applyMiddleware } from "redux";
import GlobalReducer from "./reducers/GlobalReducer";
import thunk from "redux-thunk";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const GlobalStore = createStore(
  GlobalReducer,
  composeEnhancer(applyMiddleware(thunk))
);
export default GlobalStore;
