import Auth from "./Auth";

class Settings {
  static apiUrl() {
    return process.env.REACT_APP_DEALFLOW_API + "/";
  }

  static apiSettings(axiosCancelToken) {
    const jwt = Auth.getJwtToken();

    return {
      cancelToken: axiosCancelToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + jwt,
      },
    };
  }

  static imageApiSettings(axiosCancelToken) {
    const jwt = Auth.getJwtToken();

    return {
      cancelToken: axiosCancelToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + jwt,
      },
      responseType: "arraybuffer",
    };
  }

  static uploadFileSettings(axiosCancelToken) {
    const jwt = Auth.getJwtToken();

    return {
      cancelToken: axiosCancelToken,
      headers: {
        Accept: "application/json",
        Authorization: "JWT " + jwt,
      },
    };
  }

  static defaultSettings(axiosCancelToken) {
    return {
      cancelToken: axiosCancelToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  static defaultURLEncodedFormSettings(axiosCancelToken) {
    return {
      cancelToken: axiosCancelToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }
}

export default Settings;
