import Axios from "axios";
import Parser from "./Parser";
import Settings from "./Settings";

class Auth {
  static AUTH_MODE =
    process.env.REACT_APP_ENVIRONMENT === "development" ? "PW" : "OKTA";

  static isOktaAuth() {
    return Auth.AUTH_MODE === "OKTA";
  }

  static getExpiresAt() {
    return localStorage.getItem("jwtExpiresAt");
  }

  static getJwtToken() {
    const jwt = localStorage.getItem("jwtToken");

    if (jwt === "") Auth.redirectToLogin(true, "no-token");

    return jwt;
  }

  static getName() {
    return localStorage.getItem("name");
  }

  static getUserId() {
    return parseInt(localStorage.getItem("jwtId"));
  }

  static getRole() {
    return localStorage.getItem("role");
  }

  /**
   * Store the currently selected unit so that it is automatically
   * set when the page is refreshed or when the user returns after a new login
   * @param {*} selectedUnitId
   */
  static setSelectedUnit(selectedUnitId) {
    localStorage.setItem("selectedUnit", selectedUnitId);
  }

  static getSelectedUnit() {
    return localStorage.getItem("selectedUnit");
  }

  static isLoggedIn() {
    let loggedIn = localStorage.getItem("jwtToken") !== null;

    if (loggedIn) {
      const now = new Date();
      const expires = new Date(Auth.getExpiresAt());

      if (now > expires) {
        Auth.logout(true, "no-token-on-start");
        loggedIn = false;
      }
    }

    return loggedIn;
  }

  static login(jwtToken, expiresAt, jwtId, name, role) {
    localStorage.setItem("jwtToken", jwtToken);
    localStorage.setItem("jwtExpiresAt", expiresAt);
    localStorage.setItem("jwtId", jwtId);
    localStorage.setItem("role", role);
    localStorage.setItem("name", name);
  }

  static logout(redirect = false, reason = null) {
    const axiosCancelRef = Axios.CancelToken.source();

    Axios.delete(
      Settings.apiUrl() + "user-auth/" + Auth.getUserId(),
      Settings.apiSettings(axiosCancelRef.token)
    )
      .then(() => {
        console.debug("Successfully logged out");
        Auth.clearLocalStorage();
        Auth.redirectToLogin(redirect, reason);
      })
      .catch(function (error) {
        const msg = Parser.parseErrorResponse(error);
        console.debug("Logout error: " + msg);
      });
  }

  static clearLocalStorage() {
    // TODO: Figure out why I did not remove selectedUnit from localStorage.
    // Cant remember so commenting out as it does not work well when switching users
    // const selectedUnit = localStorage.getItem("selectedUnit");
    localStorage.clear();
    // localStorage.setItem("selectedUnit", selectedUnit);
  }

  static redirectToLogin(redirect = false, reason = null) {
    let url = "/login";

    if (redirect || reason) url = url + "?";
    if (redirect) url = url + "redirect=" + window.location.pathname;
    if (redirect && reason) url = url + "&";
    if (reason) url = url + "reason=" + reason;

    window.location.replace(url);
  }

  static initialUserState() {
    return {
      loggedIn: false,
      jwtToken: null,
      jwtExpiresAt: null,
      jwtId: null,
      role: null,
    };
  }
}

export default Auth;
