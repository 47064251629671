class Utils {
  /**
   * Convert large numbers to a shorter version eg. 1,000,000 = 1M
   */
  static toShortCurrencyFormat(total) {
    if (total < 1e3) return total;
    if (total >= 1e3 && total < 1e6) return +(total / 1e3).toFixed(1) + "K";
    if (total >= 1e6 && total < 1e9) return +(total / 1e6).toFixed(1) + "M";
    if (total >= 1e9 && total < 1e12) return +(total / 1e9).toFixed(1) + "B";
    if (total >= 1e12) return +(total / 1e12).toFixed(1) + "T";
  }

  static capitalize(word) {
    word = word.replace("_", "");
    //eslint-disable-next-line
    return word.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
  }

  static cloneObject(objToClone) {
    return JSON.parse(JSON.stringify(objToClone));
  }

  static excludeObjProperties(obj, keysToExclude) {
    let newObj = {};

    for (const prop in obj) {
      if (!keysToExclude.includes(prop)) {
        newObj[prop] = obj[prop];
      }
    }

    return newObj;
  }

  static findItemInArray(items, propertyName, valueToFind) {
    if (!items) {
      throw new Error(
        "Method findItemInArray requires items param to be an array. Name: " +
          propertyName +
          " Value: " +
          valueToFind
      );
    }

    return items.find(function (item) {
      return item[propertyName] === valueToFind;
    });
  }

  static findItemIndex(items, propertyName, valueToFind) {
    return items.findIndex(function (item) {
      return item[propertyName] === valueToFind;
    });
  }

  static findIndex(items, valueToFind) {
    return items.findIndex(function (item) {
      return item === valueToFind;
    });
  }

  static containsElement(parent, child) {
    if (!child || !child.parentElement) return false;
    if (child.parentElement === parent) return true;

    return Utils.containsElement(parent, child.parentElement);
  }

  static generateKey() {
    return parseInt(Date.now() / 1000)
      .toString()
      .substr(1);
  }

  static removeItemFromArray(list, valueToRemove) {
    if (!list) return [];

    var index = list.indexOf(valueToRemove);
    if (index !== -1) {
      list.splice(index, 1);
    }
    return list;
  }

  static removeListItemByVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return item[propertyName] !== valueToRemove;
    });
  }

  static removeListItemByIntVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return parseInt(item[propertyName]) !== parseInt(valueToRemove);
    });
  }

  static renameObjectKey(oldObj, oldKey, newKey) {
    const newObj = {};

    Object.keys(oldObj).forEach((key) => {
      const value = oldObj[key];

      if (key === oldKey) {
        newObj[newKey] = value;
      } else {
        newObj[key] = value;
      }
    });

    return newObj;
  }

  static getQueryParams() {
    const params = window.location.search.replace("?", "").split("&");
    let result = {};

    params.forEach(function (param) {
      const parts = param.split("=");
      result[parts[0]] = parts[1];
    });

    return result;
  }

  static getQueryParam(key) {
    const params = window.location.search.replace("?", "").split("&");
    let paramVal = "";

    params.forEach(function (param) {
      const parts = param.split("=");

      if (parts[0] === key) {
        paramVal = parts[1];
      }
    });

    return paramVal;
  }

  static getIdParam() {
    const params = window.location.pathname.split("/");

    return params.pop();
  }

  static getStatusBadge(status) {
    if (status === "locked") return "danger";

    return "success";
  }

  static ensureJson(response) {
    if (typeof response.data === "string") {
      throw new Error(
        "Response does not seem to be valid json. Check the developer console"
      );
    }

    if (!response.headers.hasOwnProperty("content-type")) {
      throw new Error("Response content type is not 'application/json'");
    }

    if (!response.headers["content-type"].includes("application/json")) {
      throw new Error("Response content type is not 'application/json'");
    }
  }

  /**
   * Browsers like Brave does not allow GA, Sentry etc. so we disable
   * 3rd party services. Also disable for development
   */
  static thirdPartyEnabled() {
    if (process.env.REACT_APP_ENVIRONMENT === "development") return false;

    //Check for the brave browser

    return window.navigator.brave !== undefined &&
      window.navigator.brave.isBrave.name === "isBrave"
      ? false
      : true;
  }

  static plural(str) {
    if (str.substr(-1) === "y") return str.substr(0, str.length - 1) + "ies";

    return str + "s";
  }

  /**
   * Remove duplicates from array.
   * [1, 1, 2, 3, 3] will become [1, 2, 3]
   * @param {*} arr
   * @returns
   */
  static unique(arr) {
    return arr
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });
  }

  static decodeBase64(str) {
    return Buffer.from(str, "base64").toString("ascii");
  }

  static confidentialTooltip() {
    return "The deal is confidential and can only be viewed by the insider list";
  }
}
export default Utils;
