import Utils from "../../services/Utils";
import {
  SET_COMPANIES,
  SET_COMPANIES_LOADING,
  SET_CONTACTS,
  SET_CONTACTS_LOADING,
  SET_DEALS,
  SET_DEALS_LOADING,
  SET_DEAL_TEAM,
  SET_IS_KANBAN,
  SET_KANBAN,
  SET_KANBAN_LOADING,
  SET_DEALS_FILTER,
  SET_USER_CONFIG,
  REMOVE_COMPANY_LIST_STATE,
  REMOVE_CONTACT_LIST_STATE,
  REMOVE_DEAL_LIST_STATE,
  REMOVE_KANBAN_STATE,
} from "../GlobalActionTypes";

const initialState = {
  sidebarShow: "responsive",
  selectedUnit: null,
};

const COMPANY_LIST_KEYS = [
  "companies",
  "isCompaniesLoading",
  "companiesLoadError",
];

const CONTACT_LIST_KEYS = [
  "contacts",
  "isContactsLoading",
  "contactsLoadError",
];

const DEAL_LIST_KEYS = [
  "deals",
  "dealsLoadError",
  "dealsStages",
  "dealValueMsg",

  "dlFilerConfidential",
  "dlFilterLarge",
  "dlFilterSearch",
  "dlFilterStage",
  "dlFilterState",
  "dlLargeDealValue",

  "isDealsLoading",
  "listShowingMsg",
  "tooltipTotal",
];

const KANBAN_KEYS = [
  "isKanban",
  "isKanbanLoading",
  "kanbanCards",
  "kanbanColumns",
  "kanbanLoadError",
];

function getUnitName(units, selectedId) {
  let selectedUnitName = "All Teams";

  if (selectedId === 0) {
    return selectedUnitName;
  }

  if (units.length > 0) {
    selectedUnitName = Utils.findItemInArray(units, "id", selectedId).name;
  }

  return selectedUnitName;
}

const GlobalReducer = (state = initialState, { type, ...action }) => {
  // console.debug(type, action);
  switch (type) {
    case "set":
      return { ...state, ...action };

    case SET_DEAL_TEAM:
      return {
        ...state,
        selectedUnit: action.selectedUnit,
        selectedUnitName: getUnitName(state.units, action.selectedUnit),
        isKanban: action.isKanban,
      };

    case SET_CONTACTS:
    case SET_CONTACTS_LOADING:
    case SET_COMPANIES:
    case SET_COMPANIES_LOADING:
    case SET_DEALS:
    case SET_DEALS_LOADING:
    case SET_KANBAN:
    case SET_KANBAN_LOADING:
    case SET_IS_KANBAN:
    case SET_DEALS_FILTER:
      return {
        ...state,
        ...action.payload,
      };

    case SET_USER_CONFIG:
      return {
        ...state,
        ...action.payload,
        selectedUnitName: getUnitName(
          action.payload.units,
          action.payload.selectedUnit
        ),
      };

    case REMOVE_CONTACT_LIST_STATE:
      return Utils.excludeObjProperties(state, CONTACT_LIST_KEYS);

    case REMOVE_COMPANY_LIST_STATE:
      return Utils.excludeObjProperties(state, COMPANY_LIST_KEYS);

    case REMOVE_DEAL_LIST_STATE:
      return Utils.excludeObjProperties(state, DEAL_LIST_KEYS);

    case REMOVE_KANBAN_STATE:
      return Utils.excludeObjProperties(state, KANBAN_KEYS);

    default:
      return state;
  }
};

export default GlobalReducer;
