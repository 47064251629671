import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import GA4React from "ga-4-react";
import "./assets/FontAwesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./App.css";

import Auth from "./services/Auth";
import { Toaster } from "react-hot-toast";
import Utils from "./services/Utils";
import Saml from "./views/pages/Saml";

const loading = (
  <div className="pt-3 text-center">
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const NewUserLayout = React.lazy(() => import("./containers/NewUserLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/Login"));
const ForgotPassword = React.lazy(() => import("./views/pages/ForgotPassword"));
const UpdatePassword = React.lazy(() => import("./views/pages/UpdatePassword"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

//Google Analytics
const GTAGID = "G-D6K0S42HLR";
let ga4react = null;

if (Utils.thirdPartyEnabled()) {
  try {
    ga4react = new GA4React(GTAGID);
    ga4react
      .initialize()
      .catch(() =>
        console.warn(
          "Could not initialize Google Analytics. This is normally due to add blockers"
        )
      );
  } catch (err) {
    console.debug("Could not initialize Google Analytics");
  }
}

class App extends Component {
  JWT_INTERVAL = 10000;
  timerId = 0;

  /**
   * If a user is logged in and they browse to a public route eg login, password reset etc
   * they get a 404. So we have to check that if the user is logged and they browse one of these
   * routes that they are redirected to /
   */
  publicRoutes = ["/login", "/forgot-password", "/update-password"];

  constructor(props) {
    super(props);
    this.state = {
      userLoggedIn: Auth.isLoggedIn(),
      redirect: "",
    };
    this.checkToken = this.checkToken.bind(this);
    this.setLoggedInState = this.setLoggedInState.bind(this);
  }

  checkToken() {
    const { userLoggedIn } = this.state;

    if (Auth.isLoggedIn() !== userLoggedIn) {
      clearInterval(this.timerId);
      Auth.logout(false, "expired-tokens");
    }
  }

  setLoggedInState(jwtId, role, redirect) {
    if (ga4react && ga4react.hasOwnProperty("gtag")) {
      ga4react.gtag("config", GTAGID, { user_id: jwtId });
      ga4react.gtag("config", GTAGID, { dealflow_role: role });
    }

    this.timerId = setInterval(this.checkToken, this.JWT_INTERVAL);
    this.setState({ userLoggedIn: true, redirect: redirect });
  }

  render() {
    let { userLoggedIn, redirect } = this.state;

    //If a user is logged in and tries to browse to login, update-password or forgot-password
    //then we log them out
    if (
      userLoggedIn &&
      ["/forgot-password", "/update-password"].includes(
        window.location.pathname
      )
    ) {
      Auth.clearLocalStorage();
      userLoggedIn = false;
    }

    //Show login page id user is not logged in
    if (!userLoggedIn) {
      const isLoginUrl = window.location.pathname === "/login";
      const redir = this.publicRoutes.includes(window.location.pathname)
        ? ""
        : "?redirect=" + window.location.pathname;

      return (
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/forgot-password"
                name="Forgot password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/update-password"
                name="Update password"
                render={(props) => <UpdatePassword {...props} />}
              />

              <Route
                exact
                path="/saml"
                name="Saml"
                render={() => <Saml loggedIn={this.setLoggedInState} />}
              />
              <Route>
                {!isLoginUrl ? <Redirect to={"/login" + redir} /> : ""}
                <Login loggedIn={this.setLoggedInState} />
              </Route>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      );
    }

    //Redirect after login
    if (redirect !== "") {
      setTimeout(() => {
        this.setState({ redirect: "" });
      }, 100);

      return (
        <BrowserRouter>
          <Redirect to={redirect} />
        </BrowserRouter>
      );
    }

    //Redirect after login
    if (this.publicRoutes.includes(window.location.pathname)) {
      window.location.replace("/");
    }

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/new-user"
              name="New User"
              render={(props) => <NewUserLayout {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
        <Toaster />
      </BrowserRouter>
    );
  }
}

export default App;
