import React from "react";
import {
    CCard
  } from "@coreui/react";

  import loginLogo from "../../assets/images/prosus-logo-white.svg";

function ProsusCard(props) {
  return (
        <CCard
        className="text-white bg-primary py-5 d-md-down-none"
        style={{ width: "44%" }}
      >
        <div className="text-center">
          <p>
            <img src={loginLogo} alt="Naspers Logo" />
          </p>
          <h2>Dealflow</h2>
          <p className="mt-3">
            Available to Prosus employees and is by invite only{" "}
          </p>
        </div>
      </CCard>
    );
  }


export default ProsusCard;
