import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FormMessage(props) {
  if (!props.valid) {
    return (
      <div className="form-text text-danger col-12 mb-3 font-weight-bold form-err">
        <FontAwesomeIcon icon={["fas", "exclamation-circle"]} /> {props.message}
      </div>
    );
  }
  return null;
}

export default FormMessage;
