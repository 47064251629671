export const SET_ACTIVITIES = "SET_ACTIVITIES";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANIES_LOADING = "SET_COMPANIES_LOADING";
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACTS_LOADING = "SET_CONTACTS_LOADING";
export const SET_DEALS = "SET_DEALS";
export const SET_DEALS_LOADING = "SET_DEALS_LOADING";
export const SET_DEAL_TEAM = "SET_DEAL_TEAM";
export const SET_KANBAN = "SET_KANBAN";
export const SET_KANBAN_LOADING = "SET_KANBAN_LOADING";
export const SET_IS_KANBAN = "SET_IS_KANBAN";
export const SET_DEALS_FILTER = "SET_DEALS_FILTER";
export const SET_USER_CONFIG = "SET_USER_CONFIG";
export const REMOVE_COMPANY_LIST_STATE = "REMOVE_COMPANY_LIST_STATE";
export const REMOVE_CONTACT_LIST_STATE = "REMOVE_CONTACT_LIST_STATE";
export const REMOVE_DEAL_LIST_STATE = "REMOVE_DEAL_LIST_STATE";
export const REMOVE_KANBAN_STATE = "REMOVE_KANBAN_STATE";
